var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-center-detail-component table-component" },
    [
      _c(
        "div",
        [
          _c(
            "a-row",
            { attrs: { gutter: 20 } },
            [
              _c("a-col", { attrs: { span: 16 } }, [
                _c(
                  "div",
                  { staticClass: "work-order-section-wrapper" },
                  [
                    _c(
                      "a-card",
                      [
                        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                          _vm._v(" 基本信息 ")
                        ]),
                        _c(
                          "a-dropdown",
                          { attrs: { slot: "extra" }, slot: "extra" },
                          [
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _vm.viewType !== _vm.ViewModeType.NEW
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "1",
                                        on: {
                                          click: function($event) {
                                            return _vm.showSVG()
                                          }
                                        }
                                      },
                                      [_vm._v(" 查看流程图 ")]
                                    )
                                  : _vm._e(),
                                _vm.viewType !== _vm.ViewModeType.NEW &&
                                _vm.flowStatus !== "end"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: {
                                          click: function($event) {
                                            return _vm.showReAssignModal(
                                              "重新分派"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 重新分派 ")]
                                    )
                                  : _vm._e(),
                                _vm.viewType === _vm.ViewModeType.UPDATE &&
                                _vm.orderStatus !== "onHold" &&
                                _vm.orderStatus !== "termination"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: {
                                          click: function($event) {
                                            return _vm.showModal("搁置")
                                          }
                                        }
                                      },
                                      [_vm._v(" 搁置流程 ")]
                                    )
                                  : _vm._e(),
                                _vm.viewType === _vm.ViewModeType.UPDATE &&
                                _vm.orderStatus === "onHold"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "3",
                                        on: {
                                          click: function($event) {
                                            return _vm.showModal("取消搁置")
                                          }
                                        }
                                      },
                                      [_vm._v(" 取消搁置 ")]
                                    )
                                  : _vm._e(),
                                _vm.viewType !== _vm.ViewModeType.NEW &&
                                _vm.creator === _vm.UserStoreModule.UserId &&
                                _vm.orderStatus !== "termination"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "4",
                                        on: {
                                          click: function($event) {
                                            return _vm.showModal("终止")
                                          }
                                        }
                                      },
                                      [_vm._v(" 终止流程 ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              { attrs: { type: "primary" } },
                              [
                                _vm._v(" 流程操作 "),
                                _c("a-icon", { attrs: { type: "down" } })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("jtl-form", {
                          ref: "basicForm",
                          on: { change: _vm.formModelChanged }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "a-col",
                {
                  staticStyle: { display: "flex", "flex-direction": "column" },
                  attrs: { span: 8 }
                },
                [
                  _c(
                    "div",
                    { staticClass: "work-order-section-wrapper" },
                    [
                      _c(
                        "a-tabs",
                        {
                          attrs: { type: "card" },
                          model: {
                            value: _vm.activeTabKey,
                            callback: function($$v) {
                              _vm.activeTabKey = $$v
                            },
                            expression: "activeTabKey"
                          }
                        },
                        [
                          _vm.viewType !== _vm.ViewModeType.VIEW &&
                          _vm.orderStatus !== "onHold" &&
                          _vm.orderStatus !== "termination"
                            ? _c(
                                "a-tab-pane",
                                { key: "1", attrs: { tab: "处理状态" } },
                                [
                                  _c(
                                    "a-radio-group",
                                    {
                                      on: { change: _vm.formActionChange },
                                      model: {
                                        value: _vm.taskId,
                                        callback: function($$v) {
                                          _vm.taskId = $$v
                                        },
                                        expression: "taskId"
                                      }
                                    },
                                    _vm._l(_vm.processModel, function(action) {
                                      return _c(
                                        "a-radio",
                                        {
                                          key: action.actionFlowKey,
                                          attrs: { value: action.actionFlowKey }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(action.actionFlowName) +
                                              " "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "max-height": "80vh",
                                        height: "80vh",
                                        "overflow-y": "scroll",
                                        "margin-top": "5px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "work-order-status-wrapper"
                                        },
                                        [_c("jtl-form", { ref: "actionForm" })],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c("div", {
                                            staticStyle: { height: "10px" }
                                          }),
                                          _c(
                                            "a-popconfirm",
                                            {
                                              attrs: {
                                                title:
                                                  _vm.viewType ===
                                                  _vm.ViewModeType.NEW
                                                    ? "确认保存?"
                                                    : "确认提交",
                                                "ok-text": "确认",
                                                "cancel-text": "取消"
                                              },
                                              on: { confirm: _vm.submitForm }
                                            },
                                            [
                                              _c(
                                                "jtl-button",
                                                { attrs: { type: "primary" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.viewType ===
                                                          _vm.ViewModeType.NEW
                                                          ? "保存"
                                                          : "提交"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.logData && _vm.logData.length > 0
                            ? _c(
                                "a-tab-pane",
                                {
                                  key: "2",
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    padding: "10px"
                                  },
                                  attrs: { tab: "处理记录" }
                                },
                                [
                                  _c(
                                    "a-timeline",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.moreTimeLine,
                                          expression: "!moreTimeLine"
                                        }
                                      ]
                                    },
                                    _vm._l(
                                      _vm.logData.filter(function(item, index) {
                                        return index < 3
                                      }),
                                      function(item, index) {
                                        return _c(
                                          "a-timeline-item",
                                          {
                                            key: index,
                                            attrs: {
                                              color:
                                                index === 0 ? "#ffa800" : "grey"
                                            }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                style: {
                                                  color:
                                                    index === 0
                                                      ? "#ffa800"
                                                      : "a6a5a3",
                                                  fontWeight: "bold",
                                                  margin:
                                                    "0px 0px 15px 0px !important",
                                                  borderRadius: "25px",
                                                  marginBottom: "5px",
                                                  border:
                                                    index === 0
                                                      ? "solid 1px #fff4df"
                                                      : "solid 1px #efefef",
                                                  display: "inline",
                                                  padding: "2px 10px",
                                                  textAlign: "center",
                                                  backgroundColor:
                                                    index === 0
                                                      ? "#fff4df"
                                                      : "#efefef"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.taskNodeName)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0px !important",
                                                  "line-height": "30px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.taskNodeName === "重派"
                                                      ? "操作人"
                                                      : "受理人："
                                                  ) +
                                                    _vm._s(item.userDisplayName)
                                                )
                                              ]
                                            ),
                                            item.assistantUsers &&
                                            item.assistantUsers.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px !important",
                                                      "line-height": "30px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "维修人：" +
                                                        _vm._s(
                                                          item.assistantUsers.join(
                                                            "、"
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0px !important",
                                                  "line-height": "30px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "流程动作：" +
                                                    _vm._s(item.name)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0px !important",
                                                  "line-height": "30px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    index ===
                                                      _vm.logData.length - 1
                                                      ? "创建时间："
                                                      : "处理时间："
                                                  ) +
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        item.createdDT
                                                      )
                                                    )
                                                )
                                              ]
                                            ),
                                            item.timeFormat
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px !important",
                                                      "line-height": "30px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "处理时长：" +
                                                        _vm._s(item.timeFormat)
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.serviceAttitude
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px !important",
                                                      "line-height": "30px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v("服务态度："),
                                                    _c("a-rate", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          item.serviceAttitude,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "serviceAttitude",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.serviceAttitude"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.responseSpeed
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px !important",
                                                      "line-height": "30px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v("响应速度："),
                                                    _c("a-rate", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          item.responseSpeed,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "responseSpeed",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.responseSpeed"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.serviceQuality
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px !important",
                                                      "line-height": "30px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v("服务质量："),
                                                    _c("a-rate", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          item.serviceQuality,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "serviceQuality",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.serviceQuality"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0px !important",
                                                  "line-height": "30px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.content
                                                      ? "评价内容：" +
                                                          item.content
                                                      : ""
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0px !important",
                                                  "line-height": "30px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.comment
                                                      ? "备注：" + item.comment
                                                      : ""
                                                  )
                                                )
                                              ]
                                            ),
                                            item.file && item.file.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items":
                                                        "flex-start"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(" 图片： "),
                                                    _c("jtl-upload-component", {
                                                      attrs: {
                                                        disabled: true,
                                                        "list-type":
                                                          "picture-card"
                                                      },
                                                      model: {
                                                        value: item.file,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "file",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.file"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "a-timeline",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.moreTimeLine,
                                          expression: "moreTimeLine"
                                        }
                                      ]
                                    },
                                    _vm._l(_vm.logData, function(item, index) {
                                      return _c(
                                        "a-timeline-item",
                                        {
                                          key: index,
                                          attrs: {
                                            color:
                                              index === 0 ? "#ffa800" : "grey"
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              style: {
                                                color:
                                                  index === 0
                                                    ? "#ffa800"
                                                    : "a6a5a3",
                                                fontWeight: "bold",
                                                margin:
                                                  "0px 0px 10px 0px !important",
                                                borderRadius: "25px",
                                                marginBottom: "5px",
                                                border:
                                                  index === 0
                                                    ? "solid 1px #fff4df"
                                                    : "solid 1px #efefef",
                                                display: "inline",
                                                padding: "2px 10px",
                                                textAlign: "center",
                                                backgroundColor:
                                                  index === 0
                                                    ? "#fff4df"
                                                    : "#efefef"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.taskNodeName))]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                margin: "0px !important",
                                                "line-height": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.taskNodeName === "重派"
                                                    ? "操作人"
                                                    : "受理人："
                                                ) + _vm._s(item.userDisplayName)
                                              )
                                            ]
                                          ),
                                          item.assistantUsers &&
                                          item.assistantUsers.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "0px !important",
                                                    "line-height": "30px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "维修人：" +
                                                      _vm._s(
                                                        item.assistantUsers.join(
                                                          "、"
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                margin: "0px !important",
                                                "line-height": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "流程动作：" + _vm._s(item.name)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                margin: "0px !important",
                                                "line-height": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  index ===
                                                    _vm.logData.length - 1
                                                    ? "创建时间："
                                                    : "处理时间 ： "
                                                ) +
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      item.createdDT
                                                    )
                                                  )
                                              )
                                            ]
                                          ),
                                          item.timeFormat
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    margin: "0px !important",
                                                    "line-height": "30px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "处理时长：" +
                                                      _vm._s(item.timeFormat)
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          item.serviceAttitude
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "0px !important",
                                                    "line-height": "30px"
                                                  }
                                                },
                                                [
                                                  _vm._v("服务态度："),
                                                  _c("a-rate", {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        item.serviceAttitude,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "serviceAttitude",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.serviceAttitude"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.responseSpeed
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "0px !important",
                                                    "line-height": "30px"
                                                  }
                                                },
                                                [
                                                  _vm._v("响应速度："),
                                                  _c("a-rate", {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value: item.responseSpeed,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "responseSpeed",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.responseSpeed"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.serviceQuality
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "0px !important",
                                                    "line-height": "30px"
                                                  }
                                                },
                                                [
                                                  _vm._v("服务质量："),
                                                  _c("a-rate", {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        item.serviceQuality,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "serviceQuality",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.serviceQuality"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                margin: "0px !important",
                                                "line-height": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.evaluation &&
                                                    item.evaluation.content
                                                    ? "评价内容：" +
                                                        item.evaluation.content
                                                    : ""
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                margin: "0px !important",
                                                "line-height": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.comment
                                                    ? "备注：" + item.comment
                                                    : ""
                                                )
                                              )
                                            ]
                                          ),
                                          item.file && item.file.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "flex-start"
                                                  }
                                                },
                                                [
                                                  _vm._v(" 图片： "),
                                                  _c("jtl-upload-component", {
                                                    attrs: {
                                                      disabled: true,
                                                      "list-type":
                                                        "picture-card"
                                                    },
                                                    model: {
                                                      value: item.file,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "file",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.file"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.logData &&
                                            _vm.logData.length > 3,
                                          expression:
                                            "logData&&logData.length>3"
                                        }
                                      ],
                                      staticStyle: {
                                        color: "#0785F1",
                                        "align-self": "flex-end"
                                      },
                                      on: {
                                        click: function() {
                                          _vm.moreTimeLine = !_vm.moreTimeLine
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.moreTimeLine
                                              ? ">>收起"
                                              : ">>更多"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.flowVisible, width: _vm.flowWidth },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [_c("div", { attrs: { id: "flow" } })]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.processModalVisible,
            title: _vm.processModalTitle
          },
          on: { ok: _vm.handleProcessOk, cancel: _vm.handleProcessCancel }
        },
        [
          _c("a-textarea", {
            attrs: {
              placeholder:
                _vm.processModalTitle === "取消搁置"
                  ? "请填写原因（选填）"
                  : "请填写原因（必填）",
              "auto-size": { minRows: 4, maxRows: 6 },
              required: "true"
            },
            model: {
              value: _vm.processModalContent,
              callback: function($$v) {
                _vm.processModalContent = $$v
              },
              expression: "processModalContent"
            }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.reAssignModalVisible, title: "重新分派" },
          on: { ok: _vm.handleReAssignOk, cancel: _vm.handleReAssignCancel }
        },
        [
          _c(
            "div",
            [
              _vm._v("受理组："),
              _c("a-tree-select", {
                staticStyle: { width: "80%" },
                attrs: {
                  "tree-data": _vm.candidateGroups,
                  placeholder: "请选择受理组"
                },
                on: { change: _vm.groupChanged },
                model: {
                  value: _vm.reAssignGroup,
                  callback: function($$v) {
                    _vm.reAssignGroup = $$v
                  },
                  expression: "reAssignGroup"
                }
              })
            ],
            1
          ),
          _c("br"),
          _vm.reAssignGroup
            ? _c(
                "div",
                [
                  _vm._v("受理人："),
                  _c("a-select", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      options: _vm.assignees,
                      placeholder: "请选择受理人"
                    },
                    model: {
                      value: _vm.reAssignPerson,
                      callback: function($$v) {
                        _vm.reAssignPerson = $$v
                      },
                      expression: "reAssignPerson"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }